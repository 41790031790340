<template>
  <div>
    <div style="height:300px;" :id="echarts" class="echarts" ref="echarts"></div>
  </div>
</template>

<script>
// 引入echarts
import * as echarts from "echarts";
export default {
  props: {
    // 接收父组件传递过来的信息
    chartData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    drawChart() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById(this.echarts));
      // 绘制图表
      myChart.setOption({
        title: {
          text: "实时流量"
        },
        tooltip: {},
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {},
        series: [
          {
            name: "销量",
            type: "line",
            data: this.chartData
          }
        ]
      });
    }
  },
  computed: {
    echarts() {
      return "echarts" + Math.random() * 100000;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.drawChart();
    });
  },
  created: () => {}
};
</script>

<style>
</style>