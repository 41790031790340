<template>
  <div>
    <el-card shadow="never">
      <div slot="header">
        <span class="iconfont icon-Gateway">&nbsp;网关名称：HOST123456</span>
      </div>
      <el-row :gutter="20">
        <el-col :span="3" :offset="0">
          <div style="text-align: center;">
            <i class="el-icon-s-finance" style="font-size:80px"></i>
          </div>

          <div class="gateway_top_left">
            <div class="iconOnline"></div>
            <div style="font-size:12px">在线</div>
          </div>
        </el-col>
        <el-col :span="12" :offset="3" style="border-right:2px dashed #d7dae2">
          <el-row :gutter="20">
            <el-col :span="6" :offset="0">
              <i class="iconfont icon-mima"></i>
              <span>网关状态</span>
            </el-col>
            <el-col :span="6" :offset="0">
              <span>终端访问量</span>
              &nbsp;
              <span>2</span>
            </el-col>
            <el-col :span="6" :offset="0">
              <span>代理源数量</span>
              &nbsp;
              <span>2</span>
            </el-col>
          </el-row>

          <el-row :gutter="20" style="margin-top:20px">
            <el-col :span="4" :offset="0">
              <i class="iconfont icon-mima"></i>
              <span>CPU使用率</span>
            </el-col>
            <el-col :span="14" :offset="0">
              <el-progress :percentage="100" :stroke-width="20" :text-inside="true"></el-progress>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top:20px">
            <el-col :span="4" :offset="0">
              <i class="iconfont icon-mima"></i>
              <span>内存</span>
            </el-col>
            <el-col :span="14" :offset="0">
              <el-progress :percentage="50" :stroke-width="20" :format="format" :text-inside="true"></el-progress>
            </el-col>
            <el-col :span="6" :offset="0"></el-col>
          </el-row>
        </el-col>
        <el-col :span="3" :offset="1" >
          <div>
            <div>实时速度</div>
            <div style=" margin-top:5px">
              <span>
                <i class="el-icon-top" style="color:#67C23A;font-size:16px;font-weight:500" />188.26MB
              </span>
              <span>
                <i class="el-icon-bottom" style="color:#F56C6C;font-size:16px;font-weight:500" />66.32MB
              </span>
            </div>
          </div>
          <div style=" margin-top:15px">
            <div>累计流量</div>
            <div style=" margin-top:5px">
              <span>
                <i class="el-icon-top" style="color:#67C23A;font-size:16px;font-weight:500" /> 188.26MB
              </span>
              <span>
                <i class="el-icon-bottom" style="color:#F56C6C;font-size:16px;font-weight:500" />66.32MB
              </span>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-top:20px">
        <el-col :span="4" :offset="6">
          <el-button type="text" size="small">选择授权</el-button>
          <span>(已授权)</span>
        </el-col>
        <el-col :span="4" :offset="0">
          <el-button type="text" size="small">关联场所</el-button>
          <span>(默认场所)</span>
        </el-col>
        <el-col :span="4" :offset="0">
          <el-button type="text" size="small">关联节目源</el-button>
          <span>(武汉电信组)</span>
        </el-col>
        <el-col :span="4" :offset="0">
          <el-switch active-text="启用设备" style="margin-top:5px"></el-switch>
        </el-col>
      </el-row>
    </el-card>

    <el-card shadow="never" style="margin-top:20px">
      <div class="echart-pie-wrap">
        <Echart :chartData="chartData1" />
      </div>
    </el-card>
  </div>
</template>
<script>
import { getGatewayApi } from "../../api/data";
import Aes from "../../utils/aes";
import Echart from "../../components/echart";
export default {
  components: {
    Echart
  },
  data() {
    return {
      chartData1: [5, 20, 36, 10, 10, 20]
    };
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    }
  },
  created() {
    this.$nextTick(() => {});
    this.initload();
  },
  methods: {
    //初始化设备
    initload() {
      getGatewayApi({ groupId: this.placesInfo.groupId }).then(res => {
        console.log(res);
      });
    },
    format(percentage) {
      return percentage != "" ? "68.89MB/500MB" : "";
    }
  }
};
</script>
<style lang='scss'>
.gateway_top_left {
  display: flex;
  justify-content: center;
  align-items: center;
  .iconOnline {
    width: 10px;
    height: 10px;
    background-color: #67c23a;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  .iconOffline {
    width: 10px;
    height: 10px;
    background-color: #f56c6c;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
}
.gateway_top_right {
  justify-content: flex-start;
}
</style>